import React, { useEffect, useState, useRef } from 'react'
import TrackingSearchForm from './forms/TrackingSearchForm'
import AMapLoader from '@amap/amap-jsapi-loader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { message, notification } from 'antd';

export default function TrackingLocation(props) {
    const [map, setMap] = useState(null)
    const [geo, setGeo] = useState(null)
    const [address, setAddress] = useState(null)
    const [amapInstance, setAmapInstance] = useState(null)
    const mapContainer = useRef(null)
    const location = useLocation()

    const onFinish = (result) => {
        if(map){
            map.clearMap()
        }
        
        if(location.pathname.indexOf('location')!==-1){
            let [lon, lat] = [result.lon / 600000, result.lat / 600000]
            var marker = new amapInstance.Marker({
                position: [lon, lat]//位置
            })
            map.add(marker);
            map.setZoomAndCenter(12, [lon, lat]);
        } else if (location.pathname.indexOf('line')!==-1){
            let _arr = result.map(point => new amapInstance.LngLat(point.lon / 600000, point.lat / 600000))

            // Create the full polyline with the path data
            var fullPolyline = new amapInstance.Polyline({
                path: _arr, // Use the full path data here
                strokeColor: "#AF5",  //线颜色
                strokeWeight: 6,      //线宽
            });
            
            var marker_start = new amapInstance.Marker({
                position: _arr[0], 
                offset: new amapInstance.Pixel(-9, -25),
                // icon: icon_blue,
                content: '<div class="marker marker-start"><div class="mark-icon"></div><span>起点</span></div>',
                title: '起点'
            });

            var marker_end = new amapInstance.Marker({
                position: _arr.slice(-1)[0], 
                offset: new amapInstance.Pixel(-9, -25),
                content: '<div class="marker marker-end"><div class="mark-icon"></div><span>终点</span></div>',
                // icon: icon_red,
                title: '终点'
            });

            
            // Add the full polyline, start, and end markers to the map
            map.add([fullPolyline, marker_start, marker_end])
            // Fit the map view after adding all elements
            map.setFitView();

            var car_marker = new amapInstance.Marker({
                map: map, // Add map here so it's added correctly
                position: _arr[0],
                icon: process.env.PUBLIC_URL + '/truck2.png',
                offset: new amapInstance.Pixel(-13, -26),
            });

            amapInstance.plugin('AMap.MoveAnimation', function () {
                car_marker.moveAlong(_arr, {
                    duration: 200,
                    autoRotation: true,
                });
            });

        }
    }

    useEffect(()=>{
        AMapLoader.load({
            key: "f9d90a22e99cf69cd4a03f492f019a58", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: ["AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            AMapUI: {
                // 是否加载 AMapUI，缺省不加载
                version: "1.1", // AMapUI 缺省 1.1
                plugins: [], // 需要加载的 AMapUI ui插件
            },
        })
            .then((AMap) => {
                setAmapInstance(AMap);
                setMap(
                    new AMap.Map(mapContainer.current.id, {
                        // isHotspot: true
                    })
                );
                setGeo(new AMap.Geocoder());
            })
            .catch((e) => {
                console.log(e);
            });

        return function cleanup() {
            map && map.destroy();
        }
    }, [])

    useEffect(()=>{
        if(geo){
            var marker = new amapInstance.Marker();
            map.on('click', function (e) {
                map.add(marker);
                let { lng, lat } = e.lnglat
                marker.setPosition([lng, lat]);
                geo.getAddress([lng, lat], function (status, result) {
                    if (status === 'complete' && result.regeocode) {
                        var address = result.regeocode.formattedAddress;
                        
                        notification.success({
                            message: '当前地址',
                            description: <div>{address} <a href={'https://www.baidu.com/s?wd='+address} target="_blank" rel="noreferrer">点击搜索</a></div>,
                            duration: 0
                        })
                        // document.getElementById('address').value = address;
                    } else {
                        message.error('根据经纬度查询地址失败')
                    }
                });
            })
        }
    }, [geo])

    return (
        <>
            <TrackingSearchForm onFinish={onFinish} />
            <div>
                <div id="container" ref={mapContainer} style={{height:500}}></div>
                
            </div>
        </>
    )
}